import "./promoLandscape.scss";
import { useEffect, useMemo, useState } from "react";
import Button from "../Button/Button";
import Container100 from "../../components/Container/Container100.jsx";
import PromoLandscapeCard from "./PromoLandscapeCard.jsx";
import PropTypes from "prop-types";
import Select from "../Select/Select.jsx";
import useMatchMedia from "../../common/useMatchMedia.js";
import Promo from "./Promo.jsx";

const PromoLandscape = (props) => {
  const { planks, hasMargins } = props;
  const [limit, setLimit] = useState(4);
  const [planksToShow, setPlanksToShow] = useState([]);
  const [theme, setTheme] = useState(null);

  useEffect(() => {
    const data = planks.filter((plank) => {
      if (theme) {
        return plank.theme === theme;
      }
      return plank;
    });
    setLimit(4);
    setPlanksToShow(data);
  }, [planks, theme]);

  const loadMore = () => setLimit(planks.length);

  const items = useMemo(
    () =>
      planks.reduce((accumulator, current) => {
        const { theme } = current;
        if (theme) {
          accumulator.push(theme);
        }
        return [...new Set(accumulator)];
      }, []),
    [planks],
  );

  const shouldLoadMore = planksToShow.length > limit && planksToShow !== limit;

  const isMobile = useMatchMedia("(max-width: 910px)");

  return (
    <>
      {/* <Container100 className="promo-landscape__dropdown-container">
        <div className="promo-landscape__dropdown-title">
          View Actions By Planks
        </div>
        <Select
          className="promo-landscape__dropdown"
          onChange={(theme) => setTheme(theme)}
          selectProps={{
            items,
            selectedItem: "All Plank Actions",
            itemToString: (x) => x,
          }}
        />
      </Container100> */}
      {planksToShow.length > 0 && (
        <Container100>
          {isMobile ? (
            planksToShow
              .slice(0, limit)
              .map((plank, index) => <Promo {...plank} key={index} hasMargins={hasMargins}/>)
          ) : (
            <PromoLandscapeCard
              planks={planksToShow.slice(0, limit).map((x) => x)}
              hasMargins={hasMargins}
            />
          )}
        </Container100>
      )}
      {shouldLoadMore && (
        <Container100 className="promo-landscape__container">
          <Button
            className="btn--big promo-landscape__button"
            onClick={loadMore}
          >
            Load More
          </Button>
        </Container100>
      )}
    </>
  );
};

PromoLandscape.propTypes = {
  planks: PropTypes.array,
  hasMargins: PropTypes.bool,
};

PromoLandscape.defaultProps = {
  planks: [],
  hasMargins: false,
};

export default PromoLandscape;
