import * as R from "ramda";
import extractRichText from "./extractRichText";

export default R.compose(
  R.applySpec({
    secondaryHeadline: R.prop("secondaryHeadline"),
    richText: R.compose(extractRichText, R.prop("richText")),
  }),
  R.defaultTo({}),
);
