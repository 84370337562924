import ContentfulLink from "../ContentfulLink/ContentfulLink";

const canRenderCards = (cards) => Array.isArray(cards) && cards.length > 0;

const PromoLandscapeCard = (props) => {
  const { planks, hasMargins } = props;
  return canRenderCards(planks) &&
  planks.map((plank, index) => {
    const { title, text, link, tag, image } = plank;
    const colorValue = tag?.color?.colorValue;
      return (
        <div
          className={
            "promo-landscape__card" + (hasMargins ? " action-card" : "")
          }
          key={index}
        >
          <div role="presentation" className="promo-landscape__content">
            <div className="promo-landscape__card-content">
              <div className="promo-landscape__card-title">{title}</div>
              <div className="promo-landscape__card-text">{text}</div>
              <ContentfulLink {...link} className="promo-landscape__card-link">
                {link?.title}
                <svg
                  aria-hidden="true"
                  role="img"
                  width="20"
                  height="16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="promo-landscape__card-link-arrow"
                >
                  <path
                    d="m19.7381 7.2374-5.6001-6.8c-.4634-.5186-1.2063-.5681-1.694-.1688-.4876.3992-.568 1.2062-.1686 1.6938L16.263 6.8H1.2C.5373 6.8 0 7.3372 0 8c0 .6628.5373 1.2 1.2 1.2h15.063l-3.9876 4.8375c-.3994.4876-.3095 1.2833.1686 1.6939.4931.4234 1.2947.3188 1.694-.1689l5.6001-6.8c.3646-.5126.3334-1.0293 0-1.525Z"
                    fill="#fff"
                  />
                </svg>
              </ContentfulLink>
            </div>
            {image && (
              <div
                className="promo-landscape__image-content"
                style={{ ["--tag-color"]: colorValue }}
              >
                {plank.tag && (
                  <div className="promo-landscape__tag">{tag.title}</div>
                )}
                {image}
              </div>
            )}
          </div>
        </div>
      );
    })
};

export default PromoLandscapeCard;
