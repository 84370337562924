import * as R from "ramda";
import { renderRichText } from "gatsby-source-contentful/rich-text";

export default R.compose(
  R.applySpec({
    slides: ({ slides }) =>
      R.map(R.compose(renderRichText, R.prop("content")))(slides),
    boxTitle: R.prop("boxTitle"),
  }),
  R.defaultTo({}),
);
