import * as R from "ramda";

import extractLink from "./extractLink.js";

export default R.compose(
  R.applySpec({
    headline: R.prop("pageHeadline"),
    description: R.prop("pageDescription"),
    author: R.prop("authorName"),
    location: R.prop("location"),
    date: R.prop("date"),
    downloadMedia: R.compose(extractLink, R.prop("downloadMedia")),
  }),
  R.defaultTo({}),
);
