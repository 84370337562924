import "./resources-sidebar.scss";

import { arrayOf, shape, string } from "prop-types";

import ContentfulLink from "../ContentfulLink/ContentfulLink.jsx";

const propTypes = {
  links: arrayOf(
    shape({ children: string, title: string, to: string, type: string }),
  ).isRequired,
};

const ResourcesSidebar = (props) => {
  const { links } = props;

  return (
    <div className="resources-sidebar">
      <div className="resources-sidebar__header">Resources</div>
      {links.map((link, idx) => (
        <ContentfulLink
          key={idx}
          className="resources-sidebar__link link--inline"
          {...link}
        />
      ))}
    </div>
  );
};

ResourcesSidebar.propTypes = propTypes;

export default ResourcesSidebar;
