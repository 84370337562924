import "./detail-page.scss";

import * as R from "ramda";

import { object, shape, string } from "prop-types";

import BreadCrumb from "../../components/BreadCrumb/BreadCrumb.jsx";
import Container100 from "../../components/Container/Container100.jsx";
import ContainerBleed from "../../components/Container/ContainerBleed.jsx";
import DetailPageHero from "../../components/DetailPageHero/DetailPageHero";
import KeyTakeaways from "../../components/KeyTakeaways/KeyTakeaways.jsx";
import Page from "../../components/Page/Page.jsx";
import PageTitle from "../../components/PageTitle/PageTitle.jsx";
import Promo2Up from "../../components/Promo2Up/Promo2Up.jsx";
import PromoLandscape from "../../components/Promo/PromoLandscape";
import ResourcesSidebar from "../../components/ResourcesSidebar/ResourcesSidebar";
import RichTextBlock from "../../components/RichTextBlock/RichTextBlock.jsx";
import SignUp from "../../components/SignUp/SignUp.jsx";
import SocialShare from "../../components/SocialShare/SocialShare.jsx";
import SubPageHero from "../../components/SubPageHero/SubPageHero.jsx";
import TwoUpTextBanner from "../../components/TwoUpTextBanner/TwoUpTextBanner.jsx";
import YouTube from "react-youtube";
import extractImageBlock from "../../common/extractImageBlock.jsx";
import extractKeyTakeaways from "../../common/extractKeyTakeaway.js";
import extractLink from "../../common/extractLink";
import extractPageTitle from "../../common/extractPageTitle.js";
import extractPromo2Up from "../../common/extractPromo2Up.js";
import extractRteBlock from "../../common/extractRteBlock.js";
import extractSubPageHero from "../../common/extractSubPageHero.js";
import extractTwoUpTextBanner from "../../common/extractTwoUpTextBanner.js";

import { graphql } from "gatsby";

const DEFAULT_COLOR_VALUE = "#bf5cc5";

const propTypes = {
  pageContext: shape({ id: string.isRequired }).isRequired,
  data: object,
  location: object,
  includesPlankPages: Boolean,
};

const DetailPage = (props) => {
  const {
    location: { pathname },
    pageContext: { id },
  } = props;

  const includesPlankPages = pathname.includes("/our-platform/");

  const data = R.compose(
    R.evolve({
      detailPageTitle: R.unless(R.isNil, extractPageTitle),
      detailPageRteBlock60: R.unless(R.isNil, extractRteBlock),
      detailPageHeaderVideo: R.unless(R.isNil, R.identity),
      detailPageRteBlockFullWidth: R.unless(R.isNil, extractRteBlock),
      detailPagePromo2Up: R.unless(R.isNil, extractPromo2Up),
      detailPageHeroImage: R.unless(R.isNil, extractImageBlock),
      detailPageResources: R.unless(R.isNil, R.map(extractLink)),
      detailPageKeyTakeaways: R.unless(R.isNil, extractKeyTakeaways),
      detailPageVideo: R.unless(R.isNil, R.identity),
      detailPageHero: R.unless(R.isNil, extractSubPageHero),
      detailPagePlanks: R.unless(R.isNil, R.map(extractImageBlock)),
      aboutUsTwoTextBanner: R.unless(R.isNil, extractTwoUpTextBanner),
    }),
    R.defaultTo({}),
    R.path(["data", "contentfulPage", "pageContent"]),
  )(props);

  const pageTitle = R.path(["data", "contentfulPage", "title"])(props);

  const tag = R.compose(
    R.defaultTo({}),
    R.path(["data", "contentfulPage", "pageTag"]),
  )(props);

  const plankTag = props?.data?.contentfulPage?.pageListingInfo?.tag;
  const colorValue = plankTag?.color?.colorValue || DEFAULT_COLOR_VALUE;

  const {
    detailPageHeroImage,
    detailPageResources,
    detailPageRteBlock60,
    detailPageRteBlockFullWidth,
    detailPageTitle,
    detailPagePromo2Up,
    detailPageKeyTakeaways,
    detailPageVideo,
    detailPageHero,
    detailPagePlanks,
    aboutUsTwoTextBanner,
  } = data;
  const isTakeActionPage = detailPageHero?.title?.includes("Take Action");

  return (
    <Page
      pageTitle={pageTitle}
      pageDescription={detailPageTitle && detailPageTitle?.description}
      className={includesPlankPages ? "detail-page-equity" : "detail-page"}
    >
      <BreadCrumb pageId={id}>breadcrumb</BreadCrumb>
      {detailPageTitle && (
        <Container100>
          <PageTitle
            tag={tag}
            plankTag={plankTag}
            backgroundColor={colorValue}
            {...detailPageTitle}
          />
        </Container100>
      )}
      {detailPageHero && <SubPageHero {...detailPageHero} />}
      {detailPageHeroImage && (
        <Container100>
          <DetailPageHero {...detailPageHeroImage} tag={tag} />
        </Container100>
      )}
      {detailPageRteBlock60 && (
        <Container100 className="detail-page__content">
          <SocialShare />
          <RichTextBlock {...detailPageRteBlock60} />
          {detailPageResources && (
            <ResourcesSidebar links={detailPageResources} />
          )}
        </Container100>
      )}
      {detailPagePlanks && (
        <Container100>
          <PromoLandscape 
            planks={detailPagePlanks}
            hasMargins={isTakeActionPage}
          />
        </Container100>
      )}
      {detailPageKeyTakeaways && (
        <Container100>
          <KeyTakeaways
            {...detailPageKeyTakeaways}
            backgroundColor={colorValue}
          />
        </Container100>
      )}
      {detailPageVideo && (
        <Container100>
          <YouTube className="you-tube-video" {...detailPageVideo} />
        </Container100>
      )}
      {detailPageRteBlockFullWidth && (
        <Container100 className="detail-page__content detail-page__content--100">
          <RichTextBlock {...detailPageRteBlockFullWidth} />
        </Container100>
      )}
      {detailPagePromo2Up && <Promo2Up {...detailPagePromo2Up} />}
      {aboutUsTwoTextBanner && (
        <ContainerBleed>
          <TwoUpTextBanner {...aboutUsTwoTextBanner} />
        </ContainerBleed>
      )}
      <ContainerBleed className="detail-page__sign-up-container">
        <SignUp
          title="Join Our Movement"
          subtitle="Sign up for our emails and learn how to get involved in Vision for Justice and help transform our criminal-legal system."
        />
      </ContainerBleed>
    </Page>
  );
};

DetailPage.propTypes = propTypes;
export default DetailPage;

export const query = graphql`
  query DetailPageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...Page
    }
  }
`;
