import "./key-takeaways.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { A11y, Navigation } from "swiper/core";
import { array, string } from "prop-types";
import { useEffect, useState } from "react";

import useAOS from "../../common/useAOS.js";

const propTypes = { boxTitle: string.isRequired, slides: array.isRequired };

SwiperCore.use([Navigation, A11y]);

const KeyTakeaways = (props) => {
  const { slides, boxTitle, backgroundColor } = props;

  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useAOS();

  return hasMounted ? (
    <section data-aos="fade-down" className="key-takeaways">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
      >
        {slides.map((slide, idx) => (
          <SwiperSlide key={idx} virtualIndex={idx}>
            <div
              className="key-takeaways__card"
              style={{
                border: `5px solid ${backgroundColor}`,
                boxShadow: `30px 30px 0px ${backgroundColor}`,
              }}
            >
              <div className="key-takeaways__title">
                <svg
                  width="56"
                  height="56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="key-takeaways__checkmark"
                >
                  <rect width="56" height="56" rx="28" fill={backgroundColor} />
                  <path
                    d="m23.8015 38.0876-9.1-9.1524c-.5467-.5498-.5467-1.4414 0-1.9913l1.9799-1.9913a1.3943 1.3943 0 0 1 1.9799 0l6.1302 6.1654 13.1302-13.2056a1.3943 1.3943 0 0 1 1.9799 0l1.9799 1.9913c.5467.5498.5467 1.4414 0 1.9913l-16.1 16.1926a1.3944 1.3944 0 0 1-1.98 0Z"
                    fill="#fff"
                  />
                </svg>
                <strong className="key-takeaways__box-title">{boxTitle}</strong>
              </div>
              <div className="key-takeaways__content">{slide}</div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {slides && slides.length > 1 && (
        <>
          <button
            className="key-takeaways__prev swiper-button-prev"
            type="button"
          >
            <svg
              width="56"
              height="56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fill="#000" d="M0 0h56v56H0z" />
              <path
                d="m27.2517 34.5061-4.596-4.4013H42v-4.4457H22.6559l4.5961-4.4006-2.931-3.2586L14 27.8819l10.321 9.8827 2.9307-3.2585Z"
                fill="#fff"
              />
            </svg>
          </button>
          <button
            className="key-takeaways__next swiper-button-next"
            type="button"
          >
            <svg
              width="56"
              height="56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fill="#000" d="M0 0h56v56H0z" />
              <path
                d="m27.2517 34.5061-4.596-4.4013H42v-4.4457H22.6559l4.5961-4.4006-2.931-3.2586L14 27.8819l10.321 9.8827 2.9307-3.2585Z"
                fill="#fff"
              />
            </svg>
          </button>
        </>
      )}
    </section>
  ) : null;
};

KeyTakeaways.propTypes = propTypes;
export default KeyTakeaways;
